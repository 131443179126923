
export default {
  computed: {
    show: {
      get() {
        return this.$store.state.app.alertNotification.show;
      },
      set(val) {
        this.$store.commit("app/SET_ALERT_NOTIFICATION", {
          ...this.$store.state.app.alertNotification,
          show: val,
        });
      },
    },
    message() {
      return this.$store.state.app.alertNotification.message;
    },
    color() {
      return this.$store.state.app.alertNotification.color;
    },
    validations() {
      return this.$store.state.app.alertNotification.validations;
    },
  },
};
