import Vue from "vue";
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

extend("required", required);
extend("max", max);
extend("date_characters", {
  validate: (value) => {
    if (/^[\d\/]*$/.test(value)) {
      return true;
    }
    return false;
  },
});
extend("date_format", {
  validate: (value) => {
    if (/^(\d{2}\/\d{2}\/\d{2}|\d{2}\/\d{2}\/\d{4}|\d{8}|\d{6})$/.test(value)) {
      return true;
    }
    return false;
  },
});
extend("valid_date", {
  validate: (value) => {
    let dateParts;
    if (value.includes("/")) {
      dateParts = value.split('/');
    } else {
      dateParts = [value.substring(0, 2), value.substring(2, 4), value.substring(4)]
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }
    if (month < 1 || month > 12) {
      return false;
    }
    if (year < 0) {
      return false;
    }
    const daysInMonth = new Date(year, month, 0).getDate();

    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  },
});
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
