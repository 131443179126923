
export default {
  name: "DefaultLayout",
  data() {
    return {
      barContracted: false,
    };
  },
  methods: {
    handleBarStatus(barStatus) {
      this.barContracted = barStatus;
    },
  },
  computed: {
    mainPadding() {
      return this.barContracted ? "contracted" : "not-contracted";
    },
  },
  watch: {
    "$vuetify.theme.dark"(newVal) {
      document.querySelector("body").classList.toggle("dark-scrollbar", newVal);
    },
  },
};
