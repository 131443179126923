export const ProjectStatus = {
  NONE: 0,
  NEW: 1,
  INTERNAL_TEST: 2,
  CLIENT_TEST: 3,
  IMPEDED: 4,
  IN_PROGRESS: 5,
  CANCELED: 6,
  FINISHED: 7,
};
