import buildFile from "./api.file";
import scope from "./api.scope";
import project from "./api.project";
import system from "./api.system";

export default (httpClient) => ({
  file: buildFile(httpClient),
  scope: scope(httpClient),
  project: project(httpClient),
  system: system(httpClient),
});
