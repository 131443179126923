export default (httpClient) => {
  const getSystems = (pageNumber, limit) => httpClient.get(`/systems?pageNumber=${pageNumber}&limit=${limit}`);
  const getSystemById = (systemId) => httpClient.get(`/systems/${systemId}`);
  const getSystemNameKeysValues = () => httpClient.get("/systems/keys-values");
  const updateSystem = (systemId, system) => httpClient.put(`/systems/${systemId}`, system);
  const deleteSystem = (systemId) => httpClient.delete(`/systems/${systemId}`);
  const updateSystemFilter = (filters) => httpClient.put("/systems/filters", filters);
  const getSystemFilter = () => httpClient.get("/systems/filters");
  const addSystem = (systemData) => httpClient.post("/systems", systemData);
  const getSystemChips = (systemId) => httpClient.get(`/systems/${systemId}/chips`);
  const updateSystemStatus = (systemId, newStatus) => httpClient.patch(`/systems/${systemId}/status`, newStatus);

  return {
    getSystems,
    getSystemById,
    getSystemNameKeysValues,
    updateSystem,
    deleteSystem,
    updateSystemFilter,
    getSystemFilter,
    addSystem,
    getSystemChips,
    updateSystemStatus,
  };
};
