import * as signalR from '@aspnet/signalr';

export default async function ({ app, store }) {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(app.$config.signalRLink)
    .build();

  try {
    await connection.start();
  } catch (err) {
    console.error('SignalR connection error: ', err);
  }

  connection.on('scope.artifact.success', action => {
    $nuxt.$emit('artifact.create.success', action);
    store.dispatch(
      "app/ALERT_NOTIFICATION_SUCCESS",
      action.message
    );
  });

  connection.on('scope.artifact.error', action => {
    $nuxt.$emit('artifact.create.error', action);
    store.dispatch(
      "app/ALERT_NOTIFICATION_WARNING",
      action.message
    );
  });
}
