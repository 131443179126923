import Vue from "vue";
import BaseTreeView from "../components/layouts/default/base-tree-view.vue";

BaseTreeView.install = function (Vue) {
  Vue.component(BaseTreeView.name, BaseTreeView);
};

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(BaseTreeView);
}

Vue.component("v-ecm-tree-view", BaseTreeView);
