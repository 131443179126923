
export default {
  data(vm) {
    return {
      fixed: true,
      miniVariant: false,
      items: [
        {
          icon: "mdi-home",
          title: "Home",
          to: "/",
        },
        {
          icon: "mdi-file",
          title: vm.$t("sidebar.validation"),
          to: "/validacao",
        },
        {
          icon: "mdi-text-box-outline",
          title: vm.$t("sidebar.system"),
          to: "/sistema",
        },
      ],
    };
  },
  methods: {
    handleAppBarClick() {
      this.miniVariant = !this.miniVariant;
      this.$emit("barStatus", this.miniVariant);
      this.fixed = !this.fixed;
    },
    handleMouseOver() {
      if (this.miniVariant) {
        this.miniVariant = false;
        this.$emit("barStatus", this.miniVariant);
      }
    },
    handleMouseLeave() {
      if (!this.fixed) {
        this.miniVariant = true;
        this.$emit("barStatus", this.miniVariant);
      }
    },
  },
  computed: {
    subheaderClasses() {
      return {
        "justify-space-around": !this.miniVariant,
        "justify-start": this.miniVariant,
      };
    },
    listClasses() {
      return {
        "pa-4": !this.miniVariant,
      };
    },
    titleClass() {
      return {
        "black--text": !this.$vuetify.theme.dark,
        "white--text": this.$vuetify.theme.dark,
      };
    },
  },
};
