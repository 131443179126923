import Vue from "vue";
import { ModuleType } from "./enumerator.moduleType";
import { ApplicationType } from "./enumerator.applicationType";
import { SystemStatus } from "./enumerator.systemStatus";
import { OperationMode } from "./enumerator.operationMode";
import { NodeType } from "./enumerator.nodetype";
import { ProjectStatus } from "./enumerator.projectStatus";
import { ProjectProtocol } from "./enumerator.projectProtocol";
import { KeyValueGroupType } from "./enumerator.keyValueGroupType";
import { AssetSessionType } from "./enumerator.assetSessionType";
import { ModuleConfigurationType } from "./enumerator.moduleConfigurationType";
import { RoomTemperatureType } from "./enumerator.roomTemperatureType";
import { ScopeStatus } from "./enumerator.scopeStatus";
import { FieldParameter } from "./enumerator.FieldParameter";
import { ArtifactType } from "./enumerator.artifactType";

const enums = {
  ApplicationType,
  SystemStatus,
  OperationMode,
  NodeType,
  ModuleType,
  ProjectStatus,
  ProjectProtocol,
  KeyValueGroupType,
  AssetSessionType,
  ModuleConfigurationType,
  RoomTemperatureType,
  ScopeStatus,
  FieldParameter,
  ArtifactType
};

Object.entries(enums).forEach(([name, value]) => {
  Vue.prototype[`$${name}`] = value;
});
