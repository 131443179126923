export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en-US","file":"en-US.js","name":"English"},{"code":"es-ES","file":"es-ES.js","name":"Español"},{"code":"pt-BR","file":"pt-BR.js","name":"Português"}],
  defaultLocale: "pt-BR",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en-US","file":"en-US.js","name":"English"},{"code":"es-ES","file":"es-ES.js","name":"Español"},{"code":"pt-BR","file":"pt-BR.js","name":"Português"}],
  localeCodes: ["en-US","es-ES","pt-BR"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "/",
  21: "e",
  22: "n",
  23: "-",
  24: "U",
  25: "S",
  26: ".",
  27: "j",
  28: "s",
  29: "\"",
  30: ",",
  31: "\"",
  32: "e",
  33: "s",
  34: "-",
  35: "E",
  36: "S",
  37: ".",
  38: "j",
  39: "s",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "a",
  48: "n",
  49: "g",
  50: "/",
  51: "e",
  52: "s",
  53: "-",
  54: "E",
  55: "S",
  56: ".",
  57: "j",
  58: "s",
  59: "\"",
  60: ",",
  61: "\"",
  62: "p",
  63: "t",
  64: "-",
  65: "B",
  66: "R",
  67: ".",
  68: "j",
  69: "s",
  70: "\"",
  71: ":",
  72: "\"",
  73: ".",
  74: ".",
  75: "/",
  76: "l",
  77: "a",
  78: "n",
  79: "g",
  80: "/",
  81: "p",
  82: "t",
  83: "-",
  84: "B",
  85: "R",
  86: ".",
  87: "j",
  88: "s",
  89: "\"",
  90: "}",
}

export const localeMessages = {
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'es-ES.js': () => import('../../lang/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
  'pt-BR.js': () => import('../../lang/pt-BR.js' /* webpackChunkName: "lang-pt-BR.js" */),
}
