import { render, staticRenderFns } from "./base-alert-notification.vue?vue&type=template&id=324d18ca&scoped=true&"
import script from "./base-alert-notification.vue?vue&type=script&lang=js&"
export * from "./base-alert-notification.vue?vue&type=script&lang=js&"
import style0 from "./base-alert-notification.vue?vue&type=style&index=0&id=324d18ca&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324d18ca",
  null
  
)

export default component.exports