
export default {
  props: {
    items: {
      type: Array,
    },
    selectedAssetId: {
      type: String,
      default: "",
    },
  },
  methods: {
    setClass(index) {
      return index !== this.items.length - 1 ? "no-tree-last" : "";
    },
    selectedAsset(assetId) {
      this.$emit("selectedAsset", assetId);
    },
    initializeData(items) {
      if (items && items.length > 0) {
        for (let i in items) {
          var dataItem = this.initializeDataItem(items[i]);
          items[i] = dataItem;
          this.initializeData(items[i].children);
        }
      }
    },
    initializeDataItem(item) {
      function Model(item) {
        this.id = item.id || null;
        this.opened = item.opened || false;
      }

      let node = Object.assign(new Model(item), item);
      let self = this;
      node.openChildren = function () {
        node.opened = true;
        self.handleRecursionNodeChildren(node, (node) => {
          node.opened = true;
        });
      };
      node.closeChildren = function () {
        node.opened = false;
        self.handleRecursionNodeChildren(node, (node) => {
          node.opened = false;
        });
      };
      return node;
    },
    handleRecursionNodeChildren(node, func) {
      if (func(node) !== false) {
        if (node.children && node.children.length > 0) {
          for (let childNode of node.children) {
            this.handleRecursionNodeChildren(childNode, func);
          }
        }
      }
    },
  },
  created() {
    this.initializeData(this.items);
  },
};
