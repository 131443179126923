export const ScopeStatus = {
  NONE: 0,
  CREATED: 1,
  CONFIGURED: 2,
  NOT_DEPLOYED: 3,
  DEPLOYED: 4,
  ARCHIVED: 5,
  GENERATING_ARTIFACTS: 6,
  FAILURE_GENERATE_ARTIFACTS: 7,
  ARTIFACTS_READY: 8,
};
