
export default {
  name: "BaseToggleTheme",
  data() {
    return {
      themeDark: false,
    };
  },
  mounted() {
    this.themeDark = localStorage.getItem("useDarkTheme") == "true";
    if (this.themeDark) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("useDarkTheme", this.$vuetify.theme.dark.toString());
    },
  },
};
