export class HttpError extends Error {
  constructor(response) {
    super('Http Error.');
    this.name = 'HttpError';
    this.message = response.config.url;
    this.response = response;
  }
}

export class BadRequestError extends HttpError {
  constructor(response) {
    super(response);
    this.name = 'BadRequestError';
  }
}

export class UnprocessableEntityError extends HttpError {
  constructor(response) {
    super(response);
    this.name = 'UnprocessableEntityError';
  }
}
