import { BadRequestError, UnprocessableEntityError } from '../error/error.http';

export default function setupInterceptors(httpClient, store, app) {
  let requestsPending = 0;
  const req = {
    pending: () => {
      requestsPending += 1;
      store.dispatch("app/SHOW_LOADING");
    },
    done: () => {
      requestsPending -= 1;
      if (requestsPending <= 0) {
        store.dispatch("app/HIDE_LOADING");
      }
    },
  };
  httpClient.interceptors.request.use(
    (config) => {
      req.pending();
      return config;
    },
    (error) => {
      requestsPending -= 1;
      req.done();
      return Promise.reject(error);
    }
  );
  httpClient.interceptors.response.use(
    (response) => {
      req.done();
      if (response.status === 400) {
        store.dispatch(
          "app/ALERT_NOTIFICATION_WARNING",
          response.data.errors[0]
        );
        return Promise.reject(new BadRequestError(response));
      }
      if (response.status === 404) {
        return Promise.reject(response);
      }
      if (response.status === 422) {
        const validations = response?.data?.errors
          .slice(0, 3)
          .map((item) => ({
            text: item.message,
          }));
        if (response?.data?.errors?.length > 3) {
          validations.push({
            text: "...",
          });
        }
        store.dispatch(
          "app/ALERT_NOTIFICATION_WARNING",
          {
            message: app.i18n.t("message.MA001"),
            validations,
          }
        );
        return Promise.reject(new UnprocessableEntityError(response));
      }
      return Promise.resolve(response.data);
    },
    (error) => {
      req.done();
      store.dispatch(
        "app/ALERT_NOTIFICATION_ERROR",
        app.i18n.t("message.ME001")
      );
      return Promise.reject(error);
    }
  );
}
