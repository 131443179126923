
export default {
  props: {
    items: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
    },
    selectedAssetId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      model: this.items,
    };
  },
  watch: {
    items(newValue) {
      this.model = newValue;
    },
  },
  computed: {
    hasRoomTemperature() {
      return (
        this.model.hasRoomTemperature ===
        this.$RoomTemperatureType.HASROOMTEMPERATURE
      );
    },
    isAsset() {
      return this.model.children == null && this.model.modules?.length >= 0;
    },
    classes() {
      return [
        { "tree-open": this.model.opened },
        { "tree-closed": !this.model.opened },
        { [this.className]: !!this.className },
      ];
    },
    classSelectedAsset() {
      if (this.selectedAssetId === this.model.id) {
        return {
          "grey lighten-2": !this.$vuetify.theme.dark,
          "grey darken-3": this.$vuetify.theme.dark,
        };
      }

      return;
    },
    treeChildrenOpen() {
      return [{ "tree-children-open": this.model.opened }];
    },
    nodeOpen() {
      return {
        "primary--text": this.model.nodeType == 1,
        "font-weight-bold": this.model.nodeType == 1,
      };
    },
  },
  methods: {
    setClass(index) {
      return index !== this.model.children?.length - 1 ? "no-tree-last" : "";
    },
    handleItemToggle(e) {
      if (!this.isAsset) {
        this.model.opened = !this.model.opened;
      }
    },
    selectedAsset(assetId) {
      this.$emit("selectedAsset", assetId);
    },
  },
};
