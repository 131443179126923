import { make } from "vuex-pathify";

export const state = () => ({
  loading: false,
  alertNotification: {
    show: false,
    title: "",
    message: "",
    validations: [],
    color: null,
  },
  updateDownload: false
});

export const mutations = make.mutations(state);

export const actions = {
  ...make.actions(state),
  SHOW_LOADING({ commit }) {
    commit("SET_LOADING", true);
  },
  HIDE_LOADING({ commit }) {
    commit("SET_LOADING", false);
  },
  ALERT_NOTIFICATION_ERROR({ commit }, message) {
    commit("SET_ALERT_NOTIFICATION", {
      message,
      color: "error",
      show: true,
    });
  },
  ALERT_NOTIFICATION_SUCCESS({ commit }, message) {
    commit("SET_ALERT_NOTIFICATION", {
      message,
      color: "success",
      show: true,
    });
  },
  ALERT_NOTIFICATION_WARNING({ commit }, message) {
    commit("SET_ALERT_NOTIFICATION", {
      message: message.message || message,
      validations: message.validations || false,
      color: "warning",
      show: true,
    });
  },
  UPDATE_DOWNLOAD_ICON({ commit }, value) {
    commit("SET_UPDATE_DOWNLOAD", value);
  },
};

export const getters = {};
