export default (httpClient) => {
  const getScopes = (systemId, projectId, pageNumber, limit) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes?pageNumber=${pageNumber}&limit=${limit}`);
  const getScopeById = (systemId, projectId, scopeId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}`);
  const getScopeHierarchy = (systemId, projectId, scopeId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/hierarchies`);
  const updateScopeFilter = (filters) => httpClient.put(`/scopes/filters`, filters);
  const getScopeFilter = () => httpClient.get(`/scopes/filters`);
  const deleteScope = (systemId, projectId, scopeId) => httpClient.delete(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}`);
  const addScope = (systemId, projectId, scopeData) => httpClient.post(`/systems/${systemId}/projects/${projectId}/scopes`, scopeData);
  const updateScope = (systemId, projectId, scopeId, scopeData) => httpClient.put(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}`, scopeData);
  const postHierarchy = (systemId, projectId, id, formData) =>
    httpClient.post(`/systems/${systemId}/projects/${projectId}/scopes/${id}/hierarchies`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  const getAssetConfigurations = (systemId, projectId, scopeId, assetId, assetSessionType) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/assets/${assetId}/configurations/${assetSessionType}`);
  const updateAssetConfigurations = (systemId, projectId, scopeId, assetId, assetSessionType, assetConfiguration) => httpClient.put(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/assets/${assetId}/configurations/${assetSessionType}`, assetConfiguration);
  const getModuleConfigurations = (systemId, projectId, scopeId, assetModuleId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/assets-modules/${assetModuleId}/configurations`);
  const getModuleConfigurationsErrors = (systemId, projectId, scopeId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/errors`);
  const updateModuleConfigurations = (systemId, projectId, scopeId, assetModuleId, moduleConfiguration) => httpClient.put(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/assets-modules/${assetModuleId}/configurations`, moduleConfiguration);
  const getFieldAssociation = (systemId, projectId, scopeId, moduleType) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/field-associations/${moduleType}`);
  const getScopeChips = (systemId, projectId, scopeId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/chips`);
  const generateArtifacts = (systemId, projectId, scopeId) => httpClient.post(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/artifacts`);
  const moduleReplicateSettings = (systemId, projectId, scopeId, assetModuleIdBase, assetModuleIdsUpdate) => httpClient.put(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/assets-modules/${assetModuleIdBase}/configurations/copy-to`, assetModuleIdsUpdate);
  const updateStatusScope = (systemId, projectId, scopeId, newStatus) => httpClient.patch(`/systems/${systemId}/projects/${projectId}/scopes/${scopeId}/status`, newStatus);

  return {
    getScopes,
    getScopeById,
    getScopeHierarchy,
    updateScopeFilter,
    getScopeFilter,
    deleteScope,
    addScope,
    updateScope,
    postHierarchy,
    getAssetConfigurations,
    updateAssetConfigurations,
    getModuleConfigurations,
    getModuleConfigurationsErrors,
    updateModuleConfigurations,
    getFieldAssociation,
    getScopeChips,
    generateArtifacts,
    moduleReplicateSettings,
    updateStatusScope
  };
};
