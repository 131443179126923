
export default {
  data() {
    return {
      messages: [],
    };
  },
  created() {
    this.getLocalStorageNotifications();
    this.$nuxt.$on("artifact.create.success", (message) => {
      this.messages.push({
        ...message,
        typeMessage: "Success",
        createdAt: Date.now(),
        clickedClass: "notification-visualized",
      });
      this.setLocalStorageNotifications();
      this.updateDownloadIcon();
    });
    this.$nuxt.$on("artifact.create.error", (message) => {
      this.messages.push({
        ...message,
        typeMessage: "Error",
        createdAt: Date.now(),
        clickedClass: "notification-visualized",
      });
      this.setLocalStorageNotifications();
      this.updateDownloadIcon();
    });
  },
  destroyed() {
    this.$nuxt.$off("artifact.create.success");
    this.$nuxt.$off("artifact.create.error");
  },
  computed: {
    amountMessages() {
      if (process.client) {
        return (
          this.messages.filter(
            (message) => message.clickedClass === "notification-visualized"
          ).length || 0
        );
      }
    },
    isDarkScrollbar() {
      return this.$vuetify.theme.dark ? "dark-scrollbar" : "";
    },
  },
  methods: {
    updateDownloadIcon() {
      this.$store.dispatch("app/UPDATE_DOWNLOAD_ICON", true);
    },
    clear() {
      localStorage.removeItem("notifications");
      this.messages = [];

      this.$store.dispatch(
        "app/ALERT_NOTIFICATION_SUCCESS",
        this.$t("message.MS014")
      );
    },
    messageClass(message) {
      return [
        message.clickedClass,
        { "message-error": message.typeMessage == "Error" },
      ];
    },
    getLocalStorageNotifications() {
      if (process.client) {
        const localStorageNotifications = localStorage.getItem("notifications");

        if (localStorageNotifications) {
          this.messages = JSON.parse(localStorageNotifications);
        }
      }
    },
    setLocalStorageNotifications() {
      if (process.client) {
        const localStorageNotifications = JSON.stringify(this.messages);
        localStorage.setItem("notifications", localStorageNotifications);
      }
    },
    hasDivider(index) {
      return index < this.messages.length - 1;
    },
    goToScope(link, index) {
      this.messages[index].clickedClass = "";
      this.setLocalStorageNotifications();
      this.$router.push(link);
    },
    calculateTimePassed(createdAt) {
      const currentTime = new Date();
      const timeDifference = currentTime - createdAt;

      const seconds = Math.floor(timeDifference / 1000);
      if (seconds < 60) {
        return `${seconds || 0} s`;
      }

      const minutes = Math.floor(timeDifference / (60 * 1000));
      if (minutes < 60) {
        return `${minutes || 0} m`;
      }

      const hours = Math.floor(timeDifference / (60 * 60 * 1000));
      if (hours < 24) {
        return `${hours || 0} h`;
      }

      const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      return `${days || 0} d`;
    },
  },
};
