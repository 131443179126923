
export default {
  name: "BaseFooter",
  computed: {
    backgroundClass() {
      return {
        "dark": this.$vuetify.theme.dark,
        "white": !this.$vuetify.theme.dark,
      };
    },
  },
};
