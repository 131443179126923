
export default {
  name: "EmptyLayout",
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data(vm) {
    return {
      pageNotFound: vm.$t("notfound.pageNotFound"),
      otherError: vm.$t("notfound.otherError"),
    };
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
      meta: [{ hid: "head", name: "head", content: "head" }],
    };
  },
  computed: {
    errorMessage() {
      if (this.error.statusCode === 404) {
        return this.pageNotFound;
      } else {
        return this.otherError;
      }
    },
  },
};
