export default (httpClient) => {
  const getProjects = (systemId, pageNumber, limit) => httpClient.get(`/systems/${systemId}/projects?pageNumber=${pageNumber}&limit=${limit}`);
  const getProjectChips = (systemId, projectId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/chips`);
  const getProjectNameAndResponsibleKeysValues = (systemId) => httpClient.get(`/systems/${systemId}/projects/keys-values`);
  const updateProjectFilter = (filters) => httpClient.put(`/projects/filters`, filters);
  const getProjectFilter = () => httpClient.get(`/projects/filters`);
  const deleteProject = (systemId, projectId) => httpClient.delete(`/systems/${systemId}/projects/${projectId}`);
  const updateStatus = (systemId, projectId, newStatus) => httpClient.patch(`/systems/${systemId}/projects/${projectId}/status`, newStatus);
  const addProject = (systemId, projectData) => httpClient.post(`/systems/${systemId}/projects`, projectData);
  const getProjectById = (systemId, projectId) => httpClient.get(`/systems/${systemId}/projects/${projectId}`);
  const updateProject = (systemId, projectId, projectData) => httpClient.put(`/systems/${systemId}/projects/${projectId}`, projectData);
  const addProjectFile = (systemId, projectId, file) => httpClient.post(`/systems/${systemId}/projects/${projectId}/files`, file);
  const getProjectFiles = (systemId, projectId) => httpClient.get(`/systems/${systemId}/projects/${projectId}/files`);
  const deleteProjectFile = (systemId, projectId, fileId) => httpClient.delete(`/systems/${systemId}/projects/${projectId}/files/${fileId}`);

  return {
    getProjects,
    getProjectChips,
    getProjectNameAndResponsibleKeysValues,
    updateProjectFilter,
    getProjectFilter,
    deleteProject,
    updateStatus,
    addProject,
    getProjectById,
    updateProject,
    addProjectFile,
    getProjectFiles,
    deleteProjectFile,
  };
};
