import buildApi from './api'
import http from './api/http'
import setupInterceptors from './api/interceptor'

export default function ({ store, app }, inject) {
  const httpClient = http(app.$config)
  const api = buildApi(httpClient)
  setupInterceptors(httpClient, store, app)
  inject('api', api)
}
