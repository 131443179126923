export const LayoutsDefaultBaseAlertNotification = () => import('../../components/layouts/default/base-alert-notification.vue' /* webpackChunkName: "components/layouts-default-base-alert-notification" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseConfirmation = () => import('../../components/layouts/default/base-confirmation.vue' /* webpackChunkName: "components/layouts-default-base-confirmation" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDatatableValidation = () => import('../../components/layouts/default/base-datatable-validation.vue' /* webpackChunkName: "components/layouts-default-base-datatable-validation" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDatatable = () => import('../../components/layouts/default/base-datatable.vue' /* webpackChunkName: "components/layouts-default-base-datatable" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDownloadButton = () => import('../../components/layouts/default/base-download-button.vue' /* webpackChunkName: "components/layouts-default-base-download-button" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseDropzone = () => import('../../components/layouts/default/base-dropzone.vue' /* webpackChunkName: "components/layouts-default-base-dropzone" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseExpansionPanel = () => import('../../components/layouts/default/base-expansion-panel.vue' /* webpackChunkName: "components/layouts-default-base-expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseFilter = () => import('../../components/layouts/default/base-filter.vue' /* webpackChunkName: "components/layouts-default-base-filter" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseFooter = () => import('../../components/layouts/default/base-footer.vue' /* webpackChunkName: "components/layouts-default-base-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseHeader = () => import('../../components/layouts/default/base-header.vue' /* webpackChunkName: "components/layouts-default-base-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseLoading = () => import('../../components/layouts/default/base-loading.vue' /* webpackChunkName: "components/layouts-default-base-loading" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseModuleConfiguration = () => import('../../components/layouts/default/base-module-configuration.vue' /* webpackChunkName: "components/layouts-default-base-module-configuration" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseNotification = () => import('../../components/layouts/default/base-notification.vue' /* webpackChunkName: "components/layouts-default-base-notification" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseSearch = () => import('../../components/layouts/default/base-search.vue' /* webpackChunkName: "components/layouts-default-base-search" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseSidebar = () => import('../../components/layouts/default/base-sidebar.vue' /* webpackChunkName: "components/layouts-default-base-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseToggleTheme = () => import('../../components/layouts/default/base-toggle-theme.vue' /* webpackChunkName: "components/layouts-default-base-toggle-theme" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseTreeItem = () => import('../../components/layouts/default/base-tree-item.vue' /* webpackChunkName: "components/layouts-default-base-tree-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultBaseTreeView = () => import('../../components/layouts/default/base-tree-view.vue' /* webpackChunkName: "components/layouts-default-base-tree-view" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultDownload = () => import('../../components/layouts/default/download/index.vue' /* webpackChunkName: "components/layouts-default-download" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultProjectBaseDropzone = () => import('../../components/layouts/default/project/base-dropzone.vue' /* webpackChunkName: "components/layouts-default-project-base-dropzone" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultProjectFilter = () => import('../../components/layouts/default/project/filter.vue' /* webpackChunkName: "components/layouts-default-project-filter" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultProjectForm = () => import('../../components/layouts/default/project/form.vue' /* webpackChunkName: "components/layouts-default-project-form" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultProjectList = () => import('../../components/layouts/default/project/list.vue' /* webpackChunkName: "components/layouts-default-project-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultProjectTable = () => import('../../components/layouts/default/project/table.vue' /* webpackChunkName: "components/layouts-default-project-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeBaseDatatableValidation = () => import('../../components/layouts/default/scope/base-datatable-validation.vue' /* webpackChunkName: "components/layouts-default-scope-base-datatable-validation" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeBaseExpansionPanel = () => import('../../components/layouts/default/scope/base-expansion-panel.vue' /* webpackChunkName: "components/layouts-default-scope-base-expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeFilter = () => import('../../components/layouts/default/scope/filter.vue' /* webpackChunkName: "components/layouts-default-scope-filter" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeList = () => import('../../components/layouts/default/scope/list.vue' /* webpackChunkName: "components/layouts-default-scope-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeTable = () => import('../../components/layouts/default/scope/table.vue' /* webpackChunkName: "components/layouts-default-scope-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultSystemFilter = () => import('../../components/layouts/default/system/filter.vue' /* webpackChunkName: "components/layouts-default-system-filter" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultSystemForm = () => import('../../components/layouts/default/system/form.vue' /* webpackChunkName: "components/layouts-default-system-form" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultSystemList = () => import('../../components/layouts/default/system/list.vue' /* webpackChunkName: "components/layouts-default-system-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultSystemTable = () => import('../../components/layouts/default/system/table.vue' /* webpackChunkName: "components/layouts-default-system-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizardFirstPage = () => import('../../components/layouts/default/wizard/first-page.vue' /* webpackChunkName: "components/layouts-default-wizard-first-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizard = () => import('../../components/layouts/default/wizard/index.vue' /* webpackChunkName: "components/layouts-default-wizard" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizardLastPage = () => import('../../components/layouts/default/wizard/last-page.vue' /* webpackChunkName: "components/layouts-default-wizard-last-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultWizardSecondPage = () => import('../../components/layouts/default/wizard/second-page.vue' /* webpackChunkName: "components/layouts-default-wizard-second-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeModuleConfigurationAssetSections = () => import('../../components/layouts/default/scope/module-configuration/asset-sections.vue' /* webpackChunkName: "components/layouts-default-scope-module-configuration-asset-sections" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeModuleConfigurationDefault = () => import('../../components/layouts/default/scope/module-configuration/configuration-default.vue' /* webpackChunkName: "components/layouts-default-scope-module-configuration-default" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeModuleConfigurationEngineering = () => import('../../components/layouts/default/scope/module-configuration/engineering.vue' /* webpackChunkName: "components/layouts-default-scope-module-configuration-engineering" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeModuleConfigurationReplicateSettings = () => import('../../components/layouts/default/scope/module-configuration/replicate-settings.vue' /* webpackChunkName: "components/layouts-default-scope-module-configuration-replicate-settings" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeModuleConfigurationRoomTemperature = () => import('../../components/layouts/default/scope/module-configuration/room-temperature.vue' /* webpackChunkName: "components/layouts-default-scope-module-configuration-room-temperature" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeModuleConfigurationSupervision = () => import('../../components/layouts/default/scope/module-configuration/supervision.vue' /* webpackChunkName: "components/layouts-default-scope-module-configuration-supervision" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeWizardConfigurationTable = () => import('../../components/layouts/default/scope/wizard/configuration-table.vue' /* webpackChunkName: "components/layouts-default-scope-wizard-configuration-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeWizardFirstPage = () => import('../../components/layouts/default/scope/wizard/first-page.vue' /* webpackChunkName: "components/layouts-default-scope-wizard-first-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeWizard = () => import('../../components/layouts/default/scope/wizard/index.vue' /* webpackChunkName: "components/layouts-default-scope-wizard" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeWizardLastPage = () => import('../../components/layouts/default/scope/wizard/last-page.vue' /* webpackChunkName: "components/layouts-default-scope-wizard-last-page" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeWizardModuleConfigurationErrorsTable = () => import('../../components/layouts/default/scope/wizard/module-configuration-errors-table.vue' /* webpackChunkName: "components/layouts-default-scope-wizard-module-configuration-errors-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefaultScopeWizardSecondPage = () => import('../../components/layouts/default/scope/wizard/second-page.vue' /* webpackChunkName: "components/layouts-default-scope-wizard-second-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
