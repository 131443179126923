import { render, staticRenderFns } from "./base-tree-view.vue?vue&type=template&id=299d1bea&"
import script from "./base-tree-view.vue?vue&type=script&lang=js&"
export * from "./base-tree-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsDefaultBaseTreeItem: require('/app/components/layouts/default/base-tree-item.vue').default})
